import { BaseFetch } from "../main-model";
export default class ReserveModel extends BaseFetch {
  getReserveBy = (data) => {
    return this.authFetch({
      url: "reserve/getReserveBy",
      method: "POST",
      body: data,
    });
  };

  getReserveById = (data) => {
    return this.authFetch({
      url: "reserve/getReserveById",
      method: "POST",
      body: data,
    });
  };

  insertReserve = (data) => {
    return this.authFetch({
      url: "reserve/insertReserve",
      method: "POST",
      body: data,
    });
  };

  updateReserveById = (data) => {
    return this.authFetch({
      url: "reserve/updateReserveById",
      method: "POST",
      body: data,
    });
  };

  updateReserveBorrowById = (data) => {
    return this.authFetch({
      url: "reserve/updateReserveBorrowById",
      method: "POST",
      body: data,
    });
  };

  deleteReserveById = (data) => {
    return this.authFetch({
      url: "reserve/deleteReserveById",
      method: "POST",
      body: data,
    });
  };

  getMileRevertLatest = (data) => {
    return this.authFetch({
      url: "reserve/getMileRevertLatest",
      method: "POST",
      body: data,
    });
  };

  getCarReserveById = (data) => {
    return this.authFetch({
      url: "reserve/getCarReserveById",
      method: "POST",
      body: data,
    });
  };

}
